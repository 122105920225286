import React from "react";
import Footer from "../templates/footer-component";
import { Router } from "@reach/router";
import NavbarComponent from "../components/Navbar";
import "./styles/all.sass";
import Profile from "./app/Profile.js";
import ContextProvider from "./context/ContextProvider";
import PrivateRoute from "./PrivateRoute";
const TemplateWrapper = ({ children }) => {
  return (
    <ContextProvider>
      <NavbarComponent />
      {children}
      <Footer />
    </ContextProvider>
  );
};

export default TemplateWrapper;
