import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { Row, Col, Button } from "react-bootstrap";
import { isLoggedIn, getUser } from "../services/auth";
const PrivateRoute = ({ component: Component, location, minimumLevel, ...rest }) => {
  const [loggedIn, setIsLoggedIn] = useState("loading");
  const [user, setUser] = useState(null);
  const [userPrivileged, setUserPrivileged] = useState(false);

  useEffect(() => {
    (async function () {
      setIsLoggedIn(await isLoggedIn());
      setUser(await getUser());
    })();
  }, []);

  useEffect(() => {
    if (!!user) {
      console.log("minimumLevel", minimumLevel);
      if (minimumLevel === undefined) {
        setUserPrivileged(true);
      } else if (minimumLevel === "admin") {
        if (user.admin) setUserPrivileged(true);
      } else if (minimumLevel === "Basic") {
        if (user.subscription === "Basic") setUserPrivileged(true);
      } else if (minimumLevel === "Premium") {
        if (user.subscription === "Premium") setUserPrivileged(true);
      }
    }
  }, [user]);
  if (user === null || loggedIn === "loading") {
    return <div>Loading..</div>;
  }

  if (user !== null && loggedIn) {
    if (userPrivileged) {
      return <Component {...rest} />;
    }
  }

  if (user !== null && loggedIn) {
    if (!userPrivileged) {
      console.log("UNAUTH", user, minimumLevel, userPrivileged);
      return (
        <Row className="d-flex flex-row justify-content-center align-items-center mx-0" style={{ height: "90vh" }}>
          <Col className="text-center">
            <h1>Access Unauthorized</h1>
            <Button onClick={() => navigate(-1)}>Go Back</Button>
          </Col>
        </Row>
      );
    }
  }

  if (!loggedIn && location.pathname !== `/login`) {
    navigate("/login");
    return null;
  }
};
export default PrivateRoute;
