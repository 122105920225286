import React, { useState, useContext } from "react";
import reduce from "lodash/reduce";
import _, { invert } from "lodash";
import { Link, navigate } from "gatsby";
import { Navbar, Nav, NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import logo from "../../static/img/frog_vector.jpg";
import StoreContext from "./context/StoreContext";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { logout } from "../services/auth";
import iconCart from "../../static/img/cart.svg";
import iconPhone from "../../static/img/phone.svg";
import iconEmail from "../../static/img/mail.svg";

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const total = reduce(items, (acc, item) => acc + item.quantity, 0);
  return [total !== 0, total];
};

const NavbarComponent = (props) => {
  const [hasItems, quantity] = useQuantity();
  const [showAdasDropdown, setShowAdasDropdown] = useState(false);
  const {
    store: { user },
  } = useContext(StoreContext);

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="navbar">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <div className="nav-top-right-mobile">
        <img
          src={iconPhone}
          alt="phone call"
          style={{ width: "1.1em", height: "1.1em", paddingBottom: "2px", filter: "invert(70%)" }}
          className="mr-2"
        />
        <a href="tel:2096020734">209-602-0734</a>
        <img
          src={iconEmail}
          alt="email"
          style={{ width: "1.1em", height: "1.1em", paddingBottom: "2px", filter: "invert(70%)" }}
          className="mr-1 ml-4"
        />
        <a href="mailto:info@frogitout.com">info@frogitout.com</a>
      </div>
      <Navbar.Collapse id="responsive-navbar-nav" className="flex-row justify-content-center text-center nav-collapse">
        <Navbar.Brand style={{ position: "relative" }}>
          <Link to="/">
            <div className="nav-name">frogitout</div>
            <img src={logo} width="75" height="auto" className="d-inline-block align-top nav-logo" alt="frogitout" />
          </Link>
        </Navbar.Brand>
        <Nav style={{ position: "relative" }}>
          <div className="nav-top-right">
            <img
              src={iconPhone}
              alt="phone call"
              style={{ width: "1.1em", height: "1.1em", paddingBottom: "2px", filter: "invert(70%)" }}
              className="mr-2"
            />
            <a href="tel:2096020734">209-602-0734</a>
            <img
              src={iconEmail}
              alt="email"
              style={{ width: "1.1em", height: "1.1em", paddingBottom: "2px", filter: "invert(70%)" }}
              className="mr-1 ml-4"
            />
            <a href="mailto:info@frogitout.com">info@frogitout.com</a>
          </div>
          <AnchorLink className="nav-link" to="/who-we-are">
            About Us
          </AnchorLink>

          <AnchorLink className="nav-link d-block d-lg-none" to="/adas">
            ADAS Calibration Equipment
          </AnchorLink>

          <NavDropdown
            title="ADAS Calibration Equipment"
            className="nav-dropdown d-none d-lg-block"
            show={showAdasDropdown}
            onMouseEnter={() => setShowAdasDropdown(true)}
            onMouseLeave={() => setShowAdasDropdown(false)}
          >
            <AnchorLink className="dropdown-item" to="/adas">
              OVERVIEW
            </AnchorLink>
            <AnchorLink className="dropdown-item" to="/adas/#x431-adas-pro">
              X431 ADAS PRO
            </AnchorLink>
            <AnchorLink className="dropdown-item" to="/adas/#x431-adas-mobile">
              X431 ADAS MOBILE
            </AnchorLink>
            <AnchorLink className="dropdown-item" to="/adas/#x431-throttle">
              X431 THROTTLE
            </AnchorLink>
          </NavDropdown>

          <AnchorLink className="nav-link" to="/cut-out-lines">
            Cut-Out Lines
          </AnchorLink>

          <AnchorLink className="nav-link" to="/glass-part-lifting-device">
            Glass Part Lifting Device
          </AnchorLink>

          <AnchorLink className="nav-link" to="/gear">
            Gear
          </AnchorLink>

          <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Coming Soon</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <AnchorLink className="nav-link" to="#">
                <span {...triggerHandler} ref={ref}>
                  Technical Support
                </span>
              </AnchorLink>
            )}
          </OverlayTrigger>

          <AnchorLink className="nav-link" to="/app/training-videos">
            Training Videos
          </AnchorLink>

          {_.isEmpty(user) ? (
            <>
              <AnchorLink className="nav-link" to="/signup">
                Sign Up
              </AnchorLink>
              <AnchorLink className="nav-link" to="/login">
                Login
              </AnchorLink>
            </>
          ) : (
            <>
              <Link className="nav-link" to="/app/dashboard">
                Dashboard
              </Link>
              <a className="nav-link" onClick={() => logout()}>
                Logout
              </a>
            </>
          )}
          <AnchorLink className="nav-link mr-2" to="/cart">
            <div style={{ position: "relative" }}>
              <img src={iconCart} alt="Logo" style={{ width: "24px" }} />
              {hasItems && <div className="cart-counter">{quantity}</div>}
            </div>
          </AnchorLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
