import { navigate } from "gatsby";
const { axiosInstance } = require("./axios");
export const isBrowser = () => typeof window !== "undefined";
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")) : {};
const setUser = (user) => window.localStorage.setItem("user", JSON.stringify(user));
export const handleLogin = (email, password) => {
  axiosInstance
    .post(`/auth/login`, {
      email,
      password,
    })
    .then(function (response) {
      navigate("/app/profile");
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const handleSignup = (name, companyName, email, password, cb) => {
  axiosInstance
    .post(`/auth/signup`, {
      name,
      companyName,
      email,
      password,
    })
    .then(function ({ data }) {
      cb(data);
    })
    .catch(function (error) {
      cb({ error });
    });
};

export const isLoggedIn = async () => {
  return await axiosInstance
    .post(`/api/checkAuth`)
    .then(function (response) {
      console.log("/api/checkAuth/", response);
      if (response.status === 200) {
        setUser(response.data.user);
        return true;
      } else {
        setUser({});
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      setUser({});
      return false;
    });
};
export const logout = () => {
  setUser({});
  axiosInstance
    .post(`/auth/logout`)
    .then(function (response) {
      console.log(response);
      navigate("/");
    })
    .catch(function (error) {
      console.log(error);
    });
};
