import React from "react";
import { Link } from "gatsby";
import { ListGroup } from "react-bootstrap";

const Dashboard = ({ active }) => {
  return (
    <ListGroup as="ul" defaultActiveKey={active}>
      <Link to="/app/dashboard">
        <ListGroup.Item as="li" variant="dark" eventKey="/app/dashboard">
          Dashboard
        </ListGroup.Item>
      </Link>
      <Link to="/app/users/manage">
        <ListGroup.Item as="li" variant="dark" eventKey="/app/users/manage">
          User Management
        </ListGroup.Item>
      </Link>
      <Link to="/app/profile">
        <ListGroup.Item as="li" variant="dark" eventKey="/app/profile">
          Profile
        </ListGroup.Item>
      </Link>
    </ListGroup>
  );
};
export default Dashboard;
